@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    vertical-align: baseline;
}

html {
    font-size: 62.5%;
    /* 1rem = 10px*/
}

body {
    /* font-weight: 400; */
    overflow-x: hidden;
    background-color: #fff !important;
}



:root {
    --color-text: #212121   
  }

ol, ul {
    list-style: none;
}

li {
    text-decoration: none;
    list-style: none;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

*:focus {
    outline: none;
}

a {
    cursor: pointer;
    text-decoration: none;
}


